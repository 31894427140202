<template>
	<v-container fluid class="mt-8">
		<v-row>
			<v-col cols="6">
				<v-select :items="i18nSettingList" v-model="selectedSetting" item-value="value" item-text="name"
					@change="getSettingByAlias" :menu-props="{ maxHeight: '100%' }" />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="10">

			</v-col>
			<v-col cols="2">
				<v-btn color="blue darken-1" @click="adminAction">{{ "Admin Action" }}</v-btn>
			</v-col>
		</v-row>


		<v-row v-if="selectedSetting == 'appversion'">
			<v-col>
				<v-card elevation-2>
					<v-card-title>{{ $t('setting.appversion') }}</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="6">
								<v-text-field v-model="appVersion" :label="$t('setting.appversion')" />
							</v-col>
							<v-col cols="6">
								<v-text-field v-model="appVersionForced" :label="$t('setting.appversionforced')" />
							</v-col>
						</v-row>
						<!-- <v-row>
							<v-col cols="6">
								<v-text-field
									v-model="appVersionIOS"
									:label="$t('setting.ios')"
								/>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="appVersionAOS"
									:label="$t('setting.aos')"
								/>
							</v-col>
						</v-row> -->
						<v-row>
							<v-col cols="6">
								<v-text-field v-model="IOSLink" :label="$t('setting.ios_link')" />
							</v-col>
							<v-col cols="6">
								<v-text-field v-model="AOSLink" :label="$t('setting.aos_link')" />
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-else-if="selectedSetting == 'question'">
			<v-col cols="12">
				<v-card class="px-3 mb-7" v-for="(item, index) in questionRowArrZh" :key="index">
					<v-card-title>
						{{ $t('setting.question_zh') + (index + 1) }}
						<v-spacer />
						<v-btn dark @click="removeMultiRow(index, 'zh')">
							<v-icon>mdi-trash-can-outline</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<v-text-field v-model="item.question" :rules="[(v) => !!v || $t('common.required')]"
									:label="$t('setting.question_zh')" />
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="item.answer" :label="$t('setting.answer_zh')" rows="2" />
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col class="justify-center d-flex flex-row">
				<v-btn dark @click="addNewMultiRow('zh')">
					{{ $t('setting.add_question_zh') }}
				</v-btn>
			</v-col>

			<v-col cols="12">
				<v-card class="px-3 mb-7" v-for="(item, index) in questionRowArrEng" :key="index">
					<v-card-title>
						{{ $t('setting.question_eng') + (index + 1) }}
						<v-spacer />
						<v-btn dark @click="removeMultiRow(index, 'eng')">
							<v-icon>mdi-trash-can-outline</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<v-text-field v-model="item.question" :rules="[(v) => !!v || $t('common.required')]"
									:label="$t('setting.question_eng')" />
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="item.answer" :label="$t('setting.answer_eng')" rows="2" />
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col class="justify-center d-flex flex-row">
				<v-btn dark @click="addNewMultiRow('eng')">
					{{ $t('setting.add_question_eng') }}
				</v-btn>
			</v-col>
		</v-row>

		<v-row v-else-if="selectedSetting == 'facilities' || selectedSetting == 'aspects'">
			<v-col cols="12">
				<v-card class="px-3 mb-7" v-for="(item, index) in optionRowArr" :key="index">
					<v-card-title>
						{{ $t('setting.option') + (index + 1) }}
						<v-spacer />
						<v-btn dark @click="removeMultiRow(index, 'zh')">
							<v-icon>mdi-trash-can-outline</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<v-text-field v-model="item.id" :rules="[(v) => !!v || $t('common.required')]" label='ID' />
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="item.textZh" :label="$t('setting.content_zh')" rows="2" />
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="item.textEng" :label="$t('setting.content_eng')" rows="2" />
							</v-col>
						</v-row>
					</v-card-text>

				</v-card>
			</v-col>
			<v-col class="justify-center d-flex flex-row">
				<v-btn dark @click="addNewMultiRow('zh')">
					{{ $t('setting.add_option') }}
				</v-btn>
			</v-col>


		</v-row>

		<v-row v-else>
			<v-col cols="12">
				<h2 class="pa-2">{{ $t('setting.content_zh') }}</h2>
				<vue-editor id="editor" v-model="settingObj.contentZh" useCustomImageHandler :editorToolbar="editorArr" />
			</v-col>
			<v-col cols="12">
				<h2 class="pa-2">{{ $t('setting.content_eng') }}</h2>
				<vue-editor id="editor" v-model="settingObj.contentEng" useCustomImageHandler :editorToolbar="editorArr" />
			</v-col>
		</v-row>

		<v-row>
			<v-col align="center" justify="center">
				<v-btn class="mx-4" @click="saveSetting" dark> Save </v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { VueEditor } from 'vue2-editor'
import api from '@/api'

export default {
	computed: {
		...mapState(['lang']),
		i18nSettingList() {
			let result = []

			this.settingList.map(item => {
				result.push({ name: this.$t('setting.' + item.alias), value: item.alias })
			})

			return result
		}
	},
	mounted() {
		this.getSettingByAlias()
	},
	components: {
		VueEditor,
	},
	methods: {
		...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
		async getSettingByAlias() {
			this.settingObj = {}
			try {
				let result = await api.get('/setting/' + this.selectedSetting)

				if (result.data) {
					this.settingObj = result.data

					if (this.selectedSetting == 'appversion') {
						this.appVersion = this.settingObj.contentZh
						this.appVersionForced = this.settingObj.contentEng
						this.IOSLink = this.settingObj.ios
						this.AOSLink = this.settingObj.android
					} else if (this.selectedSetting == 'question') {
						// let tmpArr = []

						// tmpArr['en'] = JSON.parse(this.settingObj['contentEng'] )
						// tmpArr['zh'] = JSON.parse(this.settingObj['contentZh'] )

						this.questionRowArrZh = JSON.parse(this.settingObj['contentZh'])
						this.questionRowArrEng = JSON.parse(this.settingObj['contentEng'])
					} else if (this.selectedSetting == 'facilities' || this.selectedSetting == 'aspects') {

						this.optionRowArr = JSON.parse(this.settingObj['contentZh'])

					}

				} else {
					if (this.selectedSetting == 'facilities' || this.selectedSetting == 'aspects') {

						this.optionRowArr = [
							{ id: '', textZh: '', textEng: '' }
						]

					}
				}

				this.action = (result.data != null) ? 'update' : 'post'
			} catch (error) {
				this.PUSH_GLOBAL_SNACK_MESSAGES({
					message: error.response.data.message,
					icon: 'mdi-close',
				})
				return false
			}
		},
		async saveSetting() {

			this.settingList.map(item => {
				if (item.alias == this.selectedSetting) {
					this.settingObj.alias = item.alias
					this.settingObj.type = item.type
				}
			})

			if (this.selectedSetting == 'appversion') {
				this.settingObj.contentZh = this.appVersion
				this.settingObj.contentEng = this.appVersionForced
				this.settingObj.ios = this.IOSLink
				this.settingObj.android = this.AOSLink
			} else if (this.selectedSetting == 'question') {
				this.settingObj['contentZh'] = JSON.stringify(this.questionRowArrZh)
				this.settingObj['contentEng'] = JSON.stringify(this.questionRowArrEng)
			} else if (this.selectedSetting == 'facilities' || this.selectedSetting == 'aspects') {
				this.settingObj['contentZh'] = JSON.stringify(this.optionRowArr)
				this.settingObj['contentEng'] = "[]";
			}

			let result = false
			try {
				if (this.action == 'post') {
					result = await api.post('/setting', this.settingObj) //create
				} else {
					result = await api.patch('/setting', this.settingObj) //update
				}

				if (result) {
					this.PUSH_GLOBAL_SNACK_MESSAGES({
						message: this.$t('global_alert.success_update_setting'),
						icon: 'mdi-check',
					})
				}
			} catch (error) {
				this.PUSH_GLOBAL_SNACK_MESSAGES({
					message: error.response.data.message,
					icon: 'mdi-close',
				})
				return false
			}
		},

		async adminAction() {

			let result = false
			try {
				result = await api.get('/services/adminaction', {}) //get

				if (result.data) {
					this.PUSH_GLOBAL_SNACK_MESSAGES({
						message: this.$t('global_alert.success_update_setting'),
						icon: 'mdi-check',
					})
				}
			} catch (error) {
				this.PUSH_GLOBAL_SNACK_MESSAGES({
					message: error.response.data.message,
					icon: 'mdi-close',
				})
				return false
			}
		},
		addNewMultiRow(lang) {
			if (this.selectedSetting == 'question') {
				if (lang == 'zh') {
					this.questionRowArrZh.push({ startAt: '', endAt: '' })
				} else {
					this.questionRowArrEng.push({ startAt: '', endAt: '' })
				}
			} else if (this.selectedSetting == 'facilities' || this.selectedSetting == 'aspects') {
				this.optionRowArr.push({ id: '', textZh: '', textEng: '' })
			}

		},
		removeMultiRow(index, lang) {
			if (this.selectedSetting == 'question') {
				if (lang == 'zh') {
					this.questionRowArrZh.splice(index, 1)
				} else {
					this.questionRowArrEng.splice(index, 1)
				}
			} else if (this.selectedSetting == 'facilities' || this.selectedSetting == 'aspects') {
				this.optionRowArr.splice(index, 1)
			}

		},
	},
	data: () => ({
		selectedSetting: 'appversion',
		action: 'post',
		// action: 'update',
		appVersionIOS: '',
		appVersionAOS: '',
		appVersion: '',
		appVersionForced: '',
		IOSLink: '',
		AOSLink: '',
		settingObj: {
			alias: '',
			type: '',
			contentZh: '',
			contentEng: '',
			nameZh: '',
			nameEng: ''
		},
		settingArray: [],
		questionRowArrZh: [
			{ question: '', answer: '' }
		],
		questionRowArrEng: [
			{ question: '', answer: '' }
		],
		optionRowArr: [
			{ id: '', textZh: '', textEng: '' }
		],

		settingList: [
			{ alias: 'appversion', type: 'string' },
			{ alias: 'facilities', type: 'json' },
			{ alias: 'aspects', type: 'json' },
			{ alias: 'merchant_tandc', type: 'html' },
			{ alias: 'user_tandc', type: 'html' },
			{ alias: 'merchant_privacypolicy', type: 'html' },
			{ alias: 'user_privacypolicy', type: 'html' },
			{ alias: 'aboutus', type: 'string' },
			{ alias: 'question', type: 'json' } //常見問題
			// {alias: 'admin_activity_logs', type: 'string'},
			//admin activity Logs
			//modification actions
		],
		editorArr: [
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
			[{ align: '' }, { align: 'center' }, { align: 'justify' }, { align: 'right' }],
			['link'],
			['image'],
		],
	}),
}
</script>
